<template>
<div class="progress-labels">
    <hr class="secondaryBorderColour">
    <button class="progress-labels--back secondaryTextColour" v-on:click="$emit('back', activeStep)">&lt; Back</button>
    <div class="z-10" v-if="activeStep == 'complete'">
        <button class="progress-labels--button progress-labels--button__complete mainBackgroundColour secondaryBorderColour secondaryTextColour"><img :src=checkIconUrl> {{translations.complete}}</button>
    </div>
    <div class="z-10 flex" v-else>
        <button v-if="activeStep == 'step-one'" class="progress-labels--button" v-bind:class="[activeStep == 'step-one' ? 'active' : 'tertiaryTextColour secondaryBackgroundColour']"><span>{{translations.step}} </span><span class="progress-labels--number">1</span></button>
        <button v-else class="progress-labels--button progress-labels--button__complete-part mainBackgroundColour secondaryBorderColour" v-on:click="$emit('back', activeStep)"><img :src=checkIconUrl></button>
        <button class="progress-labels--button" v-bind:class="[activeStep == 'step-two' ? 'active' : 'tertiaryTextColour secondaryBackgroundColour']"><span>{{translations.step}} </span><span class="progress-labels--number">2</span></button>
    </div>
</div>
</template>
<script>
import Page from '../models/page.js';
export default {
    props: ['active-step', 'translations'],
    computed: {
        stepOne() {
            return Page.stepOne;
        },
        checkIconUrl() {
            return `${ this.translations.baseUrl }/uploads/${ translations.checkIcon }`;
        }
    }
}
</script>