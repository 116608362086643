<template>
<div class="result-measurement-preference">
    <button class="completeTextColour completeBorderColour" v-on:click="$emit('switch', 'IN')" v-bind:class="[unitOfMeasurement == 'IN' ? 'active secondaryBackgroundColour' : '']">{{ translations.inchesText }}</button>
    <button class="completeTextColour completeBorderColour" v-on:click="$emit('switch', 'CM')" v-bind:class="[unitOfMeasurement == 'CM' ? 'active secondaryBackgroundColour' : '']">{{ translations.centimetresText }}</button>
</div>
</template>
<script>
export default {
    props: ['unit-of-measurement', 'translations'],
}
</script>