// These are helper methods
var convertCMToMM = function(measurement) {
    return measurement * 10;
}
var convertINToMM = function(measurement) {
    return measurement * 25.4;
}
var convertMMToCM = function(measurement) {
    return measurement / 10;
}
var convertMMToIN = function (measurement) {
    return measurement / 25.4;
}
var roundToTwo = function (num) {    
    return +(Math.round(num + "e+2")  + "e-2");
}

// This is the exported method
module.exports = function (measurements, productMeasurementInMM, preference) {
    productMeasurementInMM = parseFloat(productMeasurementInMM);
    var floorInMM = 0,
        mountInMM = 0,
        resultInMM = 0;

    if(measurements.floor.unit == "CM") {
        floorInMM = convertCMToMM(parseFloat(measurements.floor.length));
    } else {
        floorInMM = convertINToMM(parseFloat(measurements.floor.length));
    }

    if(measurements.mount.unit == "CM") {
        mountInMM = convertCMToMM(parseFloat(measurements.mount.length));
    } else {
        mountInMM = convertINToMM(parseFloat(measurements.mount.length));
    }
    console.log(productMeasurementInMM, mountInMM, floorInMM);
    resultInMM = productMeasurementInMM + mountInMM + floorInMM;

    if(preference == "CM") {
        return roundToTwo(convertMMToCM(resultInMM));
    } else {
        return roundToTwo(convertMMToIN(resultInMM));
    }
}