<template>
    <div class="main--tv-mount-selector">
        <input :disabled="products == null" ref="searchInput" autofocus type="search" v-model="searchQuery" :placeholder="placeholder" class="inputTextColour inputBorderColour" v-bind:class="[isSearching ? 'active inputBorderColor' : '']">
        <img src="/assets/images/search.svg"  class="main--tv-mount-selector--icon">
        <div v-if="isSearching" class="main--tv-mount-selector--results">
            <ul class="list-reset inputBackgroundColour inputTextColour inputBorderColour">
                <li :key="product.id+product.function" v-for="product in results"><button type="button" class="main--tv-mount-selector--button" v-on:click="$emit('select-model', product)"><span v-html="product.title"></span> <span class="main--tv-mount-selector--label" v-if="product.showFunctionLabel">{{ product.function }}</span></button></li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
    props: ['placeholder', 'products'],
    data: function() {
        return {
            searchQuery: "",
        };
    },
    methods: {
    },
    computed: {
        isSearching() {
            return this.searchQuery.length > 2;
        },
        results() {
            if(this.searchQuery.trim() == "") {
                return this.products;
            }
            return this.products.filter((product) => {
                return product.title.toLowerCase().includes(this.searchQuery.toLowerCase());
            });
        }
    }
}
</script>