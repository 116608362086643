<template>
<div class="flex flex-col">
    <progress-labels :translations=translations active-step="step-two" v-on:back="back"></progress-labels>
    <h1 class="step-one--heading secondaryTextColour" v-html="product.title"></h1>
    <div class="step-one--content">
        <p class="step-one--instructions stepInstructionsTextColour" v-html="translations.stepTwoInstructions"></p>
        <div class="step-one--input-wrapper inputBorderColour">
            <input type="number" v-on:keyup.enter="$emit('next-step')" v-model=measurement.length :placeholder="placeholderText"  step=".01" min="0">
            <button 
                v-bind:class="[measurement.unit == 'IN' ? 'stepTwoBackgroundColour text-white' : 'secondaryInputBackgroundColour']"
                v-on:click="changeUnit('IN')"
            >
                IN
            </button>
            <button 
                v-bind:class="[measurement.unit == 'CM' ? 'stepTwoBackgroundColour text-white' : 'secondaryInputBackgroundColour']"
                v-on:click="changeUnit('CM')"
            >
                CM
            </button>
        </div>
        <div>
            <img src="/assets/images/generic-tv-height.png">
        </div>
    </div>
    <button class="step-one--next-button primaryBackgroundColour primaryTextColour" v-on:click="$emit('next-step')" :disabled=valid>{{translations.nextStep}}</button>
</div>
</template>
<script>
import ProgressLabels from './ProgressLabels.vue';
import validLength from '../utils/valid-length.js';

export default {
    props: ['product', 'measurement', 'defaultUnit', 'translations'],
    created() {
        // When page loads, set the unit of measurement to the same as the previous page.
        // Unless we're coming from the complete page, in which case keep it as is.
        console.log()
        if(this.measurement.length == null) {
            this.measurement.unit = this.defaultUnit;
        }
    },
    data() {
        return {
        }
    },
    components: {
        'progress-labels': ProgressLabels,
    },
    computed: {
        valid() {
            return validLength(this.measurement.length);
        },
        placeholderText() {
            if(this.measurement.unit == "IN") {
                return this.translations.enterInches;
            } else {
                return this.translations.enterCentimetres;
            }
        }
    },
    methods: {
        changeUnit(unit) {
            console.log("hello");
            this.measurement.unit = unit;
        },
        back(stage) {
            this.$emit('back', stage);
        }
    }
}
</script>