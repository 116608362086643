console.log("Hello, world");

window.Vue = require('vue');

import Header from './components/Header.vue';
import ModelSearch from './components/ModelSearch.vue';
import StepOne from './components/StepOne.vue';
import StepTwo from './components/StepTwo.vue';
import Complete from './components/Complete.vue';
import invalidLength from './utils/valid-length.js';
import axios from 'axios';
import Page from './models/page.js';
import Product from './models/product.js';

axios.defaults.baseURL = window.location.href;

Vue.directive('focus', {
    inserted: function(el) {
        el.focus()
    }
});

const app = new Vue({
    el: '#app',
    components: {
        'model-search': ModelSearch,
        'step-one': StepOne,
        'step-two': StepTwo,
        'complete': Complete,
        'app-header': Header,
    },
    delimiters: ['${', '}'],
    data: {
        // Change back to home
        page: Page.home,
        translations: null,
        products: null,
        activeProduct: null,
        measurements: {
            mount: {
                length: null,
                unit: "CM",
            },
            floor: {
                length: null,
                unit: "CM"
            }
        },
    },
    created() {
        this.translations = window.translations;
        axios.get('/api/v1/products.json')
        .then((response) => {
            var tempProducts = [];
            response.data.data.forEach((product, index) => {
                tempProducts.push(new Product(product));
                if(product.functions.flat.isEnabled && product.functions.tilt.isEnabled) {
                    // Shallow copy - nested properties are still referenced
                    let copy = Object.assign({}, product);
                    copy["primaryFunction"] = "tilted"
                    tempProducts.push(new Product(copy));
                }
            });
            this.products = tempProducts.sort((a,b) => {
                return a.title.localeCompare(b.title);
            });


        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })
    },
    computed: {
        isHome() {
            return this.page == Page.home;
        },
        isStepOne() {
            return this.page == Page.stepOne;
        },
        isStepTwo() {
            return this.page == Page.stepTwo;
        },
        isComplete() {
            return this.page == Page.complete;
        },
        shouldCentralise() {
            return (this.page == Page.home) ? 'centralise' : '';
        }
    },
    methods: {
        selectModel(model) {
            console.log(model.title);
            this.page = Page.stepOne;
            this.activeProduct = model;
        },
        nextStep() {
            console.log("Got it...", this.page, this.measurements.mount.length);
            if(this.page == Page.stepOne && !invalidLength(this.measurements.mount.length)) {
            console.log("doing...")
                this.page = Page.stepTwo;
            } else if(this.page == Page.stepTwo && !invalidLength(this.measurements.floor.length)) {
                this.page = Page.complete;
            }
        },
        back(stage) {
            // If we're going from step two back to step one: clear the step two data
            // This is so when they do come back to step two the old data isn't prepopulated
            console.log(stage);
            if(stage == Page.complete) {
                stage = Page.stepTwo;
            } else if(stage == Page.stepTwo) {
                this.measurements.floor = {
                    length: null,
                    unit: "CM"
                }
                stage = Page.stepOne;
            } else if(stage == Page.stepOne) {
                this.measurements.mount = {
                    length: null,
                    unit: "CM"
                }
                stage = Page.home;
            }
            this.page = stage
        },
        reset() {
            this.page = Page.home;
            this.activeProduct = null;
            this.measurements = {
                mount: {
                    length: null,
                    unit: "CM",
                },
                floor: {
                    length: null,
                    unit: "CM"
                }
            };
        }
    }
});
