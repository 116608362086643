<template>
    <header class="headerBackgroundColour primaryTextColour">
        <div class="header--container">
            <a :href=translations.siteUrl class="header--logo"><img :title=translations.branding.heading :src=logoUrl></a>
            <div class="header--title"><h1 v-if="hasProductTitle" v-html="product.title"></h1></div>
            <div class="header--languages">
                <select v-model="selectedLanguage" v-on:change="switchLanguage">
                    <option v-bind:value="site.id" :key="site.id" v-for="site in translations.siteList" :data-url="site.url">{{ site.language.toUpperCase() }}</option>
                </select>
                <div class="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                    <svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.5 6L6.53109 0.75H0.468911L3.5 6Z" fill="white" fill-opacity="0.7"/>
                    </svg>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    props: ['product', 'translations'],
    data() {
        return {
            seletedLanguage: 0,
        }
    },
    created() {
        this.selectedLanguage = this.translations.siteId;
    },
    computed: {
        hasProductTitle() {
            return (this.product && this.product.title);
        },
        logoUrl() {
            return `${ this.translations.baseUrl }/uploads/${ translations.branding.logo }`;
        },
    },
    methods: {
        switchLanguage(e) {
            console.log(this.selectedLanguage);
            let newSite = translations.siteList.filter((site) => {
                return site.id == this.selectedLanguage;
            });

            if(newSite.length > 0) {
                window.location.href = newSite[0].url;
            }
        }
    }
}
</script>