<template>
<div>
    <progress-labels :translations=translations active-step="complete" v-on:back="back"></progress-labels>
    <p class="complete--instructions completeTextColour" v-html="translations.completeInstructions"></p>
    <div class="flex">
        <div ref="measurementLine" class="complete--measurement-line"></div>
        <div ref="completeResultBox" class="complete--results primaryBackgroundColour primaryTextColour">
            <div>
                <span class="complete--results--number">{{ calculatedResult }}</span><span class="complete--results--unit">{{unitOfMeasurement}}</span>
            </div>
        </div>
    </div>
    <p class="complete--instructions completeTextColour">{{ translations.completeInstructions2 }}</p>
    <result-measurement-preference :translations=translations :unit-of-measurement="unitOfMeasurement" v-on:switch="switchUnitOfMeasurement"></result-measurement-preference>
    <div class="complete--reset--wrapper">
        <button v-on:click="$emit('reset')" class="complete--reset completeTextColour completeBorderColour">{{ translations.startOverText }}</button>
    </div>
</div>
</template>
<script>
import ResultMeasurementPreference from './ResultMeasurementPreference.vue';
import ProgressLabels from './ProgressLabels.vue';
import calculation from '../utils/calculation.js';

export default {
    props: ['product', 'measurements', 'defaultUnit', 'translations'],
    data() {
        return {
            unitOfMeasurement: '',
        }
    },
    created() {
        this.unitOfMeasurement = this.defaultUnit;
    },
    mounted() {
        console.log(this.$refs.measurementLine);
        this.$refs.measurementLine.style.marginTop = `${(this.$refs.completeResultBox.offsetHeight / 2) + 1}px`;

    },
    components: {
        'progress-labels': ProgressLabels,
        'result-measurement-preference': ResultMeasurementPreference,
    },
    methods: {
        switchUnitOfMeasurement(unitOfMeasurement) {
            this.unitOfMeasurement = unitOfMeasurement;
        },
        back(stage) {
            console.log('step...', stage);
            this.$emit('back', stage);
        }
    },
    computed: {
        calculatedResult() {
            if(this.product !== null && this.measurements !== null) {
                return calculation(this.measurements, this.product.measurementInMM, this.unitOfMeasurement);
            }
            return 0;
        }
    }

}
</script>